import { React, useEffect, useCallback, use } from 'react';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyle from '../src/components/GlobalStyles';
import SiteHeader from '../src/components/SiteHeader';
import SiteFooter from '../src/components/SiteFooter';
import DataLoader from '../src/components/DataLoader';
import StoreNumberWrapper from '../src/components/StoreNumberWrapper';
import { AppWrapper } from '../src/context/app';
import SEO from '../next-seo.config';
import { BASE_URL, IS_PRODUCTION_OR_STAGING } from '../constants';
import 'slick-carousel/slick/slick.css';
import FullStorySetup from '../src/components/FullStorySetup';
import { useGLTF } from '@react-three/drei'
import { cloudinary3dAsset } from '../src/helpers/CloudinaryHelpers'

const Site = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const SiteMain = styled.main`
  position: relative;
`;

const queryClient = new QueryClient({});

function App({ Component, pageProps }) {
  const router = useRouter();
  const path = router.asPath === '/' ? router.asPath : `${router.asPath}/`;
  const url = `${BASE_URL}${path}`;
  useEffect(() => {
    useGLTF.preload(cloudinary3dAsset('m23d/armless.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/armless-recliner.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/Console.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/LAF-Chaise.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/LAF-Recliner.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/Media-Console.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/RAF-Chaise.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/RAF-Recliner.glb'));
    useGLTF.preload(cloudinary3dAsset('m23d/Wedge.glb'));
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <FullStorySetup />
      <DefaultSeo {...SEO} />
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Site>
            <SiteHeader />
            <SiteMain>
              <StoreNumberWrapper>
                <DataLoader>
                  <Component {...pageProps} />
                </DataLoader>
              </StoreNumberWrapper>
            </SiteMain>
            <SiteFooter />
          </Site>
        </AppWrapper>
      </QueryClientProvider>
    </>
  );
}

export default App;
