import React, { useState } from "react";
import styled from "styled-components";

const ConfiguratorIcon = styled.svg`
  --background-color: white;
  --border-color: var(--rtg-blue);
  --glyph-color: var(--dark-tyrian-blue);
`;

export const CirclePlus = () => {
  return (
    <ConfiguratorIcon width="100%" height="100%" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="22" height="22" rx="11" fill="var(--background-color)" stroke="var(--border-color)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.75 7.71875V6.46875H10.25V7.71875V10.25H7.71875H6.46875V12.75H7.71875H10.25V15.2812V16.5312H12.75V15.2812V12.75H15.2812H16.5312V10.25H15.2812H12.75V7.71875Z" fill="var(--glyph-color)"/>
    </ConfiguratorIcon>  
  )
}

export const CircleTrash = () => {
  return (
    <ConfiguratorIcon width="100%" height="100%" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="22" height="22" rx="11" fill="var(--background-color)" stroke="var(--border-color)"/>      
      <path d="M9.37498 5.125V5.83333H5.83331V7.25H6.54165V16.4583C6.54165 16.8341 6.6909 17.1944 6.95658 17.4601C7.22225 17.7257 7.58259 17.875 7.95831 17.875H15.0416C15.4174 17.875 15.7777 17.7257 16.0434 17.4601C16.3091 17.1944 16.4583 16.8341 16.4583 16.4583V7.25H17.1666V5.83333H13.625V5.125H9.37498ZM7.95831 7.25H15.0416V16.4583H7.95831V7.25ZM9.37498 8.66667V15.0417H10.7916V8.66667H9.37498ZM12.2083 8.66667V15.0417H13.625V8.66667H12.2083Z" fill="var(--glyph-color)"/>
    </ConfiguratorIcon>  
  )
}

<path d="M9.37498 5.125V5.83333H5.83331V7.25H6.54165V16.4583C6.54165 16.8341 6.6909 17.1944 6.95658 17.4601C7.22225 17.7257 7.58259 17.875 7.95831 17.875H15.0416C15.4174 17.875 15.7777 17.7257 16.0434 17.4601C16.3091 17.1944 16.4583 16.8341 16.4583 16.4583V7.25H17.1666V5.83333H13.625V5.125H9.37498ZM7.95831 7.25H15.0416V16.4583H7.95831V7.25ZM9.37498 8.66667V15.0417H10.7916V8.66667H9.37498ZM12.2083 8.66667V15.0417H13.625V8.66667H12.2083Z" fill="var(--glyph-color)"/>

export const ArrowsOut = () => {
  return (
    <ConfiguratorIcon width="100%" height="100%" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4321 4.77271H21.4094V8.74998" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.8413 10.3409L21.4095 4.77271" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.47776 20.6818H5.50049V16.7046" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.0687 15.1136L5.50049 20.6818" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.4094 16.7046V20.6818H17.4321" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.8413 15.1136L21.4095 20.6818" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.50049 8.74998V4.77271H9.47776" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.0687 10.3409L5.50049 4.77271" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </ConfiguratorIcon>
  )
}

export const TechGlyph = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 20.625L9.625 13.75L4.125 11.6875L13.75 1.375L12.375 8.25L17.875 10.3125L8.25 20.625Z" stroke="#0050B5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const ReclinerGlyph = () => {
  return (
    <svg width="281" height="176" viewBox="0 0 281.01 176.35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#0050B5" d="m90.11,176.35c-2.17,0-4.15-1.21-5.13-3.14L.63,8.39C-.82,5.56.31,2.09,3.14.63c2.83-1.45,6.31-.33,7.76,2.51l82.73,161.67h181.61c3.18,0,5.77,2.58,5.77,5.76s-2.58,5.77-5.77,5.77H90.11Z"/>
      <path fill="#0050B5" d="m190.88,153.28c-3.18,0-5.77-2.58-5.77-5.77,0-44.56-36.25-80.81-80.81-80.81-13.01,0-25.4,2.98-36.82,8.86-2.83,1.46-6.31.34-7.77-2.49-1.46-2.83-.34-6.31,2.49-7.77,12.88-6.63,27.44-10.14,42.1-10.14,50.92,0,92.34,41.42,92.34,92.34,0,3.18-2.58,5.77-5.77,5.77Z"/>
    </svg>
  )
}

export const ArrowLeftGlyph = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0938 12.5H3.90625" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.9375 5.46875L3.90625 12.5L10.9375 19.5312" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};

export const ArrowCounterClockwiseGlyph = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.16992 8.9574H2.85742V4.6449" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.91211 17.0883C7.01756 18.1946 8.42631 18.9483 9.96015 19.2539C11.494 19.5595 13.084 19.4033 14.5291 18.805C15.9741 18.2068 17.2093 17.1935 18.0783 15.8932C18.9473 14.5928 19.4112 13.064 19.4112 11.5C19.4112 9.93602 18.9473 8.40717 18.0783 7.10686C17.2093 5.80654 15.9741 4.79318 14.5291 4.19496C13.084 3.59675 11.494 3.44055 9.96015 3.74614C8.42631 4.05173 7.01756 4.80537 5.91211 5.91172L2.85742 8.95742" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const TrashGlyph = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M4.12502 0.375V1.16667H0.166687V2.75H0.958354V13.0417C0.958354 13.4616 1.12517 13.8643 1.4221 14.1613C1.71903 14.4582 2.12176 14.625 2.54169 14.625H10.4584C10.8783 14.625 11.281 14.4582 11.5779 14.1613C11.8749 13.8643 12.0417 13.4616 12.0417 13.0417V2.75H12.8334V1.16667H8.87502V0.375H4.12502ZM2.54169 2.75H10.4584V13.0417H2.54169V2.75ZM4.12502 4.33333V11.4583H5.70835V4.33333H4.12502ZM7.29169 4.33333V11.4583H8.87502V4.33333H7.29169Z"
        fill="var(--glyph-color)"
      />
    </svg>
  )
}

export const ArrowsOutGlyph = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4062 4.6875H20.3125V8.59375" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.8438 10.1562L20.3125 4.6875" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.59375 20.3125H4.6875V16.4062" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.1562 14.8438L4.6875 20.3125" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.3125 16.4062V20.3125H16.4062" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.8438 14.8438L20.3125 20.3125" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.6875 8.59375V4.6875H8.59375" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.1562 10.1562L4.6875 4.6875" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const MinusGlyph = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.90625 12.5H21.0938" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const PlusGlyph = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.90625 12.5H21.0938" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 3.90625V21.0938" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const XGlyph = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5312 5.46875L5.46875 19.5312" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0625"/>
      <path d="M19.5312 19.5312L5.46875 5.46875" stroke="var(--glyph-color)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0625"/>
    </svg>
  )
}

export const ThreeDGlyph = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.85"
      height="21.06"
      fill="none"
      version="1.1"
      viewBox="0 0 20.85 21.06"
    >
      <g
        stroke="var(--glyph-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
        transform="matrix(.94116 0 0 .94183 -.47 -.482)"
      >
        <path d="M16.383 6.953c-.867-3.436-2.647-5.791-4.703-5.791-2.907 0-5.265 4.714-5.265 10.53 0 5.816 2.358 10.53 5.265 10.53.36 0 .713-.072 1.053-.21m2.31-8.522l4.017 1.958-1.959 4.016"></path>
        <path d="M19.051 15.451c-1.9.932-4.5 1.506-7.37 1.506-5.817 0-10.531-2.357-10.531-5.265 0-2.907 4.714-5.265 10.53-5.265 5.095 0 9.344 1.81 10.32 4.212"></path>
      </g>
    </svg>
  )
}

export const RotateGlyph = () => {
  const RotateWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.8);
    transform-origin: center;
    @media (min-width: 50em) {
      transform: translate(-50%, -50%) scale(.93);
    }
  `;

  return (
    <RotateWrap>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        fill="none"
        viewBox="0 0 23 24"
      >
        <path
          stroke="var(--glyph-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M17.898 8.42l2.147-2.147-2.147-2.148"
        ></path>
        <path
          stroke="var(--glyph-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M2.864 12A5.736 5.736 0 018.59 6.274h11.454M5.011 15.58l-2.147 2.148 2.147 2.147"
        ></path>
        <path
          stroke="var(--glyph-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.045 12a5.736 5.736 0 01-5.727 5.727H2.864"
        ></path>
      </svg>
    </RotateWrap>
  )
}