import { useQuery } from 'react-query';
import * as API from '../services/API';
import QueryConfig from '../constants/Queries';

const getStoreProducts = async (storeNumber, zip) => {
  const data = await API.storeProducts(storeNumber, zip);
  return data;
};

export default function useStoreProducts(
  storeNumber,
  zip,
  options = {
    enabled: true,
    onError: (error, variables, context) => {
      console.error('∆API ERROR', { error, variables, context });
    },
    onSettled: () => {},
    onSuccess: (data) => {
      console.log('∆API useStoreProducts', data);
    },
    retry: 3,
  }
) {
  const { enabled, onError, onSettled, onSuccess, retry } = options;
  return useQuery(
    [QueryConfig.cacheKeys.storeProducts, storeNumber, zip],
    () => getStoreProducts(storeNumber, zip),
    {
      enabled,
      onError,
      onSettled,
      onSuccess,
      retry,
    }
  );
}
