export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';

export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export const IS_STAGING =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';

export const NOT_PRODUCTION =
  process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production';

export const CURRENT_VERSION = process.env.NEXT_PUBLIC_SENTRY_DIST || 'local';

export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';

export const MODULAR_VERSION_LINK_URL = `https://40200.roomstogo.com`;
export const MODULAR_VERSION_LINK_TEXT = 'Switch to ModularOne';

export const DOTCOM_URL = 'https://www.roomstogo.com/furniture/collections/modulartwo?keywords=modulartwo';

export const WEB_USER_STORE_NUMBER = '970';

export const PRIVACY_NOTICE_URL =
  'https://legal.roomstogo.com/rtg-online#contract-fxauo3tp2';
export const TERMS_OF_USE_URL =
  'https://legal.roomstogo.com/rtg-online#contract-7siltltgm';

export const SORTED_COLORS = ['TERAMO CHARCOAL', 'TERAMO SADDLE'];

export const COLOR_NAME_MAP = {
  'TERAMO CHARCOAL': 'Charcoal',
  'TERAMO SADDLE': 'Saddle',  
};

export const COLOR_VALUE_MAP = {
  'TERAMO CHARCOAL': '#5A5957',
  'TERAMO SADDLE': '#886F5C',
};

export const CONFIGURATION_CATEGORIES = [
  {
    id: 'small',
    name: 'Small',
    title: 'Small room, **serious comfort.**',
    teaser:
      'Studio apartment? She shed? Here are some designs that make the most of every square foot.',
  },
  {
    id: 'medium',
    name: 'Medium',
    title: 'Not too big, not too small. **Just right.**',
    teaser:
      'Whether it’s for a home office or comfy living room, these designs have the flexibility you’re looking for.',
  },
  {
    id: 'large',
    name: 'Large',
    title: 'Large room? **Go big.**',
    teaser:
      'When the room gets bigger, so do our sectionals. See how more pieces add even more comfort to your room.',
  },
  {
    id: 'all',
    name: 'All',
    title: 'Here’s inspiration for spaces of all sizes.',
  },
];

export const CONFIGURATOR_GRID_PAD = 100;

export const CLOUDINARY_CLOUD_NAME =
  process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_IMAGE_ID_PREFIX =
  process.env.NEXT_PUBLIC_CLOUDINARY_IMAGE_ID_PREFIX;
export const CLOUDINARY_UPLOAD_PRESET = 'user_uploads';

/*
 rtgId is found by combining the DetailCategory and Variation from the api
 Most often, Variation is """, we only use it for CONSOLE+MEDIA (CONSOLEMEDIA)

  BUMPER OTTOMAN
  CONSOLE
  RAF CHAISE
  LAF CHAISE
  ARMLESS CHAIR
  WEDGE/CORNER
  RAF CHAIR
  LAF CHAIR
  SWIVEL
  TOSS (which we ignore)
  CONSOLEMEDIA

 */


// NOTE: Using ProductDescriptions for dev. need to talk with RTG about this
export const PIECE_TYPE_MAPPING = [
  { id: 'armless', rtgId: 'ARMLESS CHAIR' },
  { id: 'armlessRecliner', rtgId: 'DUAL POWER ARMLESS RECLINER' },
  { id: 'corner', rtgId: 'WEDGE' },
  { id: 'reclinerLeft', rtgId: 'DUAL POWER LAF RECLINER' },
  { id: 'reclinerRight', rtgId: 'DUAL POWER RAF RECLINER' },
  { id: 'chaiseLeft', rtgId: 'DUAL POWER LAF RECLINING CHAISE' },
  { id: 'chaiseRight', rtgId: 'DUAL POWER RAF RECLINING CHAISE' },
  { id: 'console', rtgId: 'WOOD TOP CONSOLE' },
  { id: 'consoleTech', rtgId: 'MEDIA CONSOLE' },
];

export const RTG_TO_INTERNAL_ID_MAPPING = PIECE_TYPE_MAPPING.reduce((rv, x) => {
  rv[x.rtgId] = x.id;
  return rv;
}, {});

export const INTERNAL_TO_RTG_ID_MAPPING = PIECE_TYPE_MAPPING.reduce((rv, x) => {
  rv[x.id] = x.rtgId;
  return rv;
}, {});

export const IN_BROWSER = typeof window !== 'undefined';
export const USER_AGENT = IN_BROWSER ? window.navigator?.userAgent : '';
export const IN_CHROME = USER_AGENT.includes('Chrome');
export const IN_SAFARI = USER_AGENT.includes('AppleWebKit') && !IN_CHROME;
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const IS_PRODUCTION_OR_STAGING = IS_PRODUCTION || IS_STAGING;