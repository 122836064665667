import React, { useCallback } from 'react';
import { useAppContext } from '../context/app';
import Link from 'next/link';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { DOTCOM_URL } from '../../constants';
import Container from './Container';
import * as Button from './Button';

const Header = styled.header`
  background-color: var(--rtg-dark-blue);
  color: var(--white);
`;

const HeaderInner = styled.div`
  height: 3.75rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RTGLogo = styled.img`
  vertical-align: middle;
  display: block;
  width: 7.75rem;
  margin-bottom: 0.375rem;
  @media (min-width: 40em) {
    display: inline-block;
    width: auto;
    height: 1.25rem;
    margin-bottom: 0;
    padding-right: 1.25rem;
    border-right: 1px solid var(--electric-lime);
    margin-right: 1.25rem;
  }
  @media (min-width: 48em) {
    height: 1.6875rem;
  }
`;

const M1Logo = styled.img`
  vertical-align: middle;
  display: block;
  width: 7.75rem;
  @media (min-width: 40em) {
    display: inline-block;
    width: auto;
    height: 1.15625rem;
  }
  @media (min-width: 48em) {
    height: 1.5625rem;
  }
`;

const XButton = styled.a`  
  font: var(--button);
  width: auto;
  padding: 0;
  font-size: 1.25rem;
  padding-bottom: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: var(--rtg-dark-blue);
  --glyph-color: var(--rtg-dark-blue);
  background-color: #eee;
  box-shadow: rgba(24, 26, 33, 0.25) 0px 0px 1px inset;
  text-decoration: none;
  border-radius: 0.1875rem;
  &:hover,
  &:focus-visible {
    color: var(--rtg-dark-blue);
    --glyph-color: var(--rtg-dark-blue);
    background-color: var(--white);
  }
  &:active {
    color: var(--dark-tyrian-blue);
    --glyph-color: var(--dark-tyrian-blue);
    background-color: var(--electric-lime);
  }
`;

const SiteHeader = () => {
  const { webUser } = useAppContext();
  const router = useRouter();
  const currentPage = router.asPath;

  const startOver = useCallback(() => {
    router.push('/');
  }, [router]);

  return (
    <Header role="banner">
      <Container>
        <HeaderInner>
          <div>
            <Link href={webUser ? DOTCOM_URL : "/"}>
              <RTGLogo src="/images/logo-rtg.svg" alt="Rooms To Go" />
            </Link>
            <Link href="/">
              <M1Logo src="/images/logo-m2.svg" alt="Modular Two" />            
            </Link>
          </div>
          <div>
            {webUser && (
              <XButton href={DOTCOM_URL} aria-label="back to roomstogo.com">x</XButton>
            )}
          </div>
        </HeaderInner>
      </Container>
    </Header>
  );
};

export default SiteHeader;
