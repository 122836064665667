import APIRequest from './api/APIRequest';
import StoreProductsRoute from './api/routes/StoreProductsRoute';
import CreateConfigRoute from './api/routes/CreateConfigRoute';
import SendToCartRoute from './api/routes/SendToCartRoute';

const request = async (route) => {
  const response = await new APIRequest(route).run();
  return response;
};

const requestResource = async (RouteModel, params) => {
  const response = await request(new RouteModel(params));
  return response;
};

const requestWithBody = (RouteModel, params, objectToSerialize) => {
  const route = new RouteModel(params);
  route.setBody(objectToSerialize);
  return request(route);
};

export const storeProducts = (storeNumber, zip) => {
  return requestResource(StoreProductsRoute, { storeNumber, zip });
};

export const createConfig = (body) => {
  return requestWithBody(CreateConfigRoute, {}, body);
};

export const sendToCart = (body) => {
  return requestWithBody(SendToCartRoute, {}, body);
};