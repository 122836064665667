import { useCallback } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import * as Sentry from '@sentry/nextjs';
import * as FormElements from './FormElements';
import * as Button from './Button';
import { useAppContext } from '../context/app';
import useStoreProducts from '../hooks/useStoreProducts';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  background-color: var(--dark-tyrian-blue);
  background-image: url('/images/M1Background.svg');
  opacity: 1;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8rem;
`;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation = styled.div`
  text-align: center;
  color: var(--white);
  width: 7rem;
  height: 7rem;
  line-height: 7rem;
  position: relative;
  &:before {
    content: "";
    display: block;
    border-style: solid;
    border-width: 2px;
    border-color: var(--electric-lime) transparent;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-name: ${loadingAnimation};
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

const DataLoader = ({ children }) => {
  const {
    activeColor,
    storeNumber,
    zip,
    setZip,
    setStoreNumber,
    setAvailableColors,
    setApiProducts,
    setActiveColor,
  } = useAppContext();

  console.log('!!! DataLoader', storeNumber, zip);

  const onSuccess = useCallback(
    (data) => {
      // TEMP TEST CODE
      console.log('!!! DataLoader onSuccess', data, 'zip:', zip);
      setAvailableColors(data.colors);
      setApiProducts(data.products);
      console.log('APIPRODUCTS', data.products);
      // If there isn't an active color or
      // the current active color isn't included
      // in the latest data from the API, reset
      // the active color.
      if (!activeColor || !data.colors.includes(activeColor)) {
        setActiveColor(data.colors[0]);
      }
      Sentry.setTag('storeNumber', storeNumber);
      Sentry.setTag('zip', zip);
    },
    [
      activeColor,
      setAvailableColors,
      setApiProducts,
      setActiveColor,
      storeNumber,
      zip,
    ]
  );

  const { status } = useStoreProducts(storeNumber, zip, { retry: false, onSuccess });

  console.log('!!! DataLoader useStoreProducts(storeNumber, zip, { retry: false, onSuccess })', useStoreProducts(storeNumber, zip, { retry: false, onSuccess }));
  
  const resetStoreNumber = useCallback(() => {
    setStoreNumber(null);
    Sentry.setTag('storeNumber', null);
  }, [setStoreNumber]);

  if (status === 'loading') {
    return (
      <Wrapper>
        <LoadingWrapper>
          <LoadingAnimation>
            Loading...
          </LoadingAnimation>
        </LoadingWrapper>
      </Wrapper>
    );
  }

  if (status === 'error') {
    return (
      <Wrapper>
        <FormElements.FormWrapper>
          <FormElements.Text>
            Could not load product data for store number {storeNumber}.
          </FormElements.Text>
          {zip && (
            <FormElements.Text>
              ZIP: {zip}
            </FormElements.Text>
          )}
          <FormElements.Actions>
            <Button.SmallUI clickHandler={resetStoreNumber}>
              Start Over
            </Button.SmallUI>
          </FormElements.Actions>{' '}
        </FormElements.FormWrapper>
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default DataLoader;
