import APIRoute from '../APIRoute';

class StoreProductsRoute extends APIRoute {
  constructor(options) {
    const { storeNumber, zip, ...otherOptions } = options;
    const routeOptions = {
      auth: false,
      method: 'GET',
      template: `/api/stores/${storeNumber}/products?zip=${zip}`,
      ...otherOptions,
    };
    super(routeOptions);
  }
}

export default StoreProductsRoute;
